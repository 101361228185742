@import 'assets/bootstrap-sass/variables';
@import 'bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr-bs5-alert';

html {
  height: 100%;
  font-size: 12px;
}

body {
  overflow-x: hidden;
  display: flex;
  height: 100%;

  .body-container {
    width: 100%;
  }
}

app-root {
  width: 100%;
}

.text-small {
  font-size: $font-size-sm;
}

.text-smaller {
  font-size: $font-size-xs;
}

.disabled-link {
  pointer-events: none;
  cursor: default;
}

.edit-in-place {
  cursor: text;
  border-radius: $border-radius-sm;
  padding-left: 0.25rem;
  padding-right: 1.250rem;
  border: 1px solid transparent;
  position: relative;

  &:hover {
    border-color: $gray-400;
  }

  &[placeholder]:empty::before {
    content: attr(placeholder);
    color: #555; 
  }

  &-icon {
    color: $gray-500;
    font-size: 0.75rem;
    margin-left: -12px;
  }

  &-edit {
    outline: none;
    border-color: $primary;

    + .edit-in-place-icon {
      color: $primary;
    }
  }
}

.w-0 {
  width: 0 !important;
}

.btn-link {
  &.text-danger {
    &:hover {
      text-decoration: underline !important;
    }
  }
}

// Placeholder
div[placeholder]:empty::before {
  content: attr(placeholder);
  color: $gray-500; 
}

div[placeholder]:empty:focus::before {
  content: "";
}

// Srollbar
::-webkit-scrollbar {
  width: 16px;

  &:horizontal {
    height: 4px;
    width: 100%;
  }
}

::-webkit-scrollbar-track {
  background: $light;
  border-left: 12px solid transparent;
  background-clip: padding-box;

  &:horizontal {
    border-left: none;
  }
}

::-webkit-scrollbar-thumb {
  background: $gray-400;
  
  border-left: 12px solid transparent;
  background-clip: padding-box;

  &:horizontal {
    border-left: none;
  }
}

::-webkit-scrollbar-thumb:hover {
  background-color: $gray-600;
}

// Containers
.container,
.container-fluid {
  padding-left: calc($grid-gutter-width / 2);
  padding-right: calc($grid-gutter-width / 2);
}

.inner-shadow:before {
  content: '';
  display: block;
  box-shadow: inset 0 0 3px rgba(0,0,0,0.15);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  height: 100%;
  z-index: 3;
}

// Colors
.text-pink {
  color: $pink;
}

.bg-pink {
  background-color: $pink;
}

.bg-translucent {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.6);
}

.text-whatsapp {
  color: $green-whatsapp;
}

// Navigation
.nav-tabs {
  .nav-item-btn {
    background-color: $nav-tabs-border-color;
    border-radius: $border-radius $border-radius 0 0;
  }
  
  .nav-item-btn-sm {
    .nav-link {
      font-size: $font-size-sm;
      border-radius: $border-radius;
      background-color: $nav-tabs-border-color;
      padding: 0.1rem $btn-padding-x-sm;
      margin: ($nav-link-padding-y + 0.1rem) calc($nav-link-padding-x / 2) ($nav-link-padding-y - 0.2rem);

      &:hover {
        border-bottom-color: transparent;
        background-color: $gray-400;
      }
    }
  }

  .nav-item-title {
    margin-left: $spacer;
    margin-right: $spacer * 3;
    color: theme-color("primary-dark");
  }

  .nav-link {
    cursor: pointer;
    color: $gray-700;
    border-bottom-width: 2px;

    &-remove-icon {
      margin-left: map_get($spacers,1);
      font-size: $font-size-sm;
      color: $gray-500;

      &:hover {
        color: $gray-700;
      }
    }
  }
}

// Toastr
.toast-container {
  .ngx-toastr {
    padding: 15px !important;
  }

  &.toast-top-center {
    top: 15px !important;

    .ngx-toastr {
      width: fit-content !important;
    }
  }

  .toast {
    background-image: none !important;

    &-message {
      font-size: $font-size-sm !important;
    }

    &-success {
      background-image: none !important;
    }

    &-error {
      background-image: none !important;
    }
  }
}

// Tooltip
[data-bs-toggle] {
  cursor: help;
}

.tooltip {
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(0,0,0,0.1);

  &[data-popper-placement^='top'] {
    margin-bottom: 0.8rem !important;
  }

  .tooltip-inner {
    text-align: left;
  }
}

// Forms
// Remove all outline styles when focused
.form-control:focus {
  box-shadow: none;
  outline: none;
}

// Carousel
.carousel {
  margin: 0 -1rem 0 -1rem;

  &-inner {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &-indicators {
    bottom: -28px;
    margin: 0;
    left: auto;
    right: 1rem;

    [data-bs-target] {
      background-color: $gray-400;
      width: 15px;

      &.active {
        background-color: $secondary;
      }
    }
  }

  &-control {
    &-next,
    &-prev {
      display: none;
    }  
  }

  &-item {
    padding: 0 1rem 0 1rem;
  }
}

.carousel-custom-controls {
  display: flex;

  .carousel-custom-control {
    color: $secondary;
    background-color: white;
    font-size: $font-size-xs;

    &:hover {
      color: $primary;
      background-color: $gray-300;
    }
  }
}
